import DrawPolygons from './polygon';

let polygon = new DrawPolygons();

$(document).ready(function () {
  let buttonDrawPolygon = $('#button-polygon-start-drawing-1');

  if (buttonDrawPolygon) {
    buttonDrawPolygon.attr('onclick', 'startPolygonDrawing(this, 0)');
  }
});

$('.apartment-units .unit-list .units > div a').hover(
  function (e) {
    var unitId = $(e.target).parents('a').data('unitId');
    $('.apartment-units .preview polygon#' + unitId + '-0-poly').addClass(
      'highlighted'
    );
    $('.apartment-units .preview polygon#' + unitId + '-1-poly').addClass(
      'highlighted'
    );
  },
  function (e) {
    var unitId = $(e.target).parents('a').data('unitId');
    $('.apartment-units .preview polygon#' + unitId + '-0-poly').removeClass(
      'highlighted'
    );
    $('.apartment-units .preview polygon#' + unitId + '-1-poly').removeClass(
      'highlighted'
    );
  }
);

$('.apartment-units .show-hide-sold-units').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  $this.toggleClass('hide');
  var isHidden = $this.hasClass('hide');

  var $rows = $this.siblings('div').find('a.sold');
  if (isHidden) {
    $rows.removeClass('show');
  } else {
    $rows.addClass('show');
  }

  $this.text(isHidden ? 'Vis solgte boliger' : 'Skjul solgte boliger');
});

if ($('.unit-list').length > 0) {
  $(window).scroll(function (e) {
    if (window.innerWidth < 1000) {
      return;
    }

    var headerHeight = 130 + ($('#wpadminbar').outerHeight() || 0) + 20; // 20px from the bottom of the header

    var $unitLists = $('.unit-list');
    var scrollTop = $(e.target).scrollTop();

    for (var i = 0; i < $unitLists.length; i++) {
      var $unitList = $($unitLists[i]);
      var offsetTop = $unitList.offset().top;
      var unitListHeight = $unitList.height();
      var $svg = $unitList.find('.preview > svg');

      var svgHeight = $svg.height();

      // Clamp the diff between 0 and (unitListHeight - svgHeight)
      // At this point the svg will be at the bottom of the unit list
      var diff = Math.min(
        Math.max(scrollTop - offsetTop + headerHeight, 0),
        unitListHeight - svgHeight
      );
      // $unitList.find('svg').css('transform', 'translateY(' + diff + 'px)');
      // $unitList.find('.label-text').css('transform', 'translateY(' + diff + 'px)');
    }
  });
}

var $polygons = $('.apartment-units .preview polygon');

if ($polygons.length > 0) {
  let svgElem = $('.unit-list .preview > svg');
  let svgWidth = svgElem.width();
  let svgHeight = svgElem.height();

  let svgViewboxWidth = svgElem.attr('viewBox').split(' ')[2];
  let relWidth = svgViewboxWidth / svgWidth;
  let svgViewboxHeight = svgElem.attr('viewBox').split(' ')[3];
  let relHeight = svgViewboxHeight / svgHeight;

  $polygons.each(function () {
    if ($(this).data('index') == '0') {
      let item = $(this).get(0);
      let bbox = item.getBoundingClientRect();
      let offsetLeft =
        $(this).offset().left - svgElem.offset().left + bbox.width / 2.15;
      let offsetTop =
        $(this).offset().top - svgElem.offset().top + bbox.height / 1.75;
      $(this)
        .parents('g')
        .find('text')
        .attr('x', relWidth * offsetLeft);
      $(this)
        .parents('g')
        .find('text')
        .attr('y', relHeight * offsetTop);
    }
  });
}

$polygons.hover(
  function (e) {
    var $this = $(this);
    var $wrapper = $this.parents('.apartment-units');
    var $list = $wrapper.find('.units');
    var $elem = $wrapper.find('.units a[data-unit-id="' + $this.data('id') + '"]');
    $elem.addClass('active')
    //$list.scrollTop($list.scrollTop() - $list.offset().top + $elem.offset().top);
    $list.scrollTo($elem, 100);

    var id = $this.data('id');
    var index = $this.data('index') === 0 ? 1 : 0;
    $('#' + id + '-' + index + '-poly').addClass('highlighted');
  },
  function (e) {
    var $this = $(this);
    var $wrapper = $this.parents('.apartment-units');
    $wrapper
      .find('.units a[data-unit-id="' + $this.data('id') + '"]')
      .removeClass('active');

    var id = $this.data('id');
    var index = $this.data('index') === 0 ? 1 : 0;
    $('#' + id + '-' + index + '-poly').removeClass('highlighted');
  }
);

$polygons.click(function (e) {
  window.location = $(this).data('link');
});

function slugify(title) {
  var str = title;
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

polygon.setPolygonCallback(function (data) {
  if (!data.polyName) {
    return;
  }

  // Update input field with new points
  var $inputs = $('[data-key="field_649152928587d"] option');
  var polygonFieldInputId =
    data.polyIndex === 0 ? '6490846909748' : '649084cf09749';

  for (var i = 0; i < $inputs.length; i++) {
    var $input = $($inputs[i]);

    if (
      $input.html() === data.polyName ||
      $input.attr('value') === data.polyName
    ) {
      $input
        .parents('.acf-row')
        .find('.acf-field-' + polygonFieldInputId + ' input')
        .val(data.points)
        .attr('value', data.points);
    }
  }

  $('.acf-block-fields').each(function () {
    var evt = document.createEvent('HTMLEvents');
    evt.initEvent('change', false, true);
    $(this)[0].dispatchEvent(evt);
  });
});

function setupFieldEditing(svgId) {
  polygon.setAllowEditing(true);
  polygon.editPolygon(svgId, 'random');
  polygon.setCircleRadius(8);
}

window.setupFieldEditing = setupFieldEditing;

function startPolygonDrawing(el, index) {
  var $svg = $('.block-editor-block-list__block.is-selected .preview > svg');
  var name = $(el).parents('.acf-row').find('[data-name="unit"] option').html();
  var slug = slugify(name);

  polygon.drawPolygon($svg.attr('id'), slug + '-' + index, name, index);
}

window.startPolygonDrawing = startPolygonDrawing;
